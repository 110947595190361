<template>
  <div>
    <!-- MOBILE -->
    <div v-if="isMobileWidth === 1">
      <div class="d-flex justify-space-between">
        <p class="font-weight-bold title black--text">TikTok Influencer</p>
      </div>
      <div v-if="isLoading" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
        <v-card
          width="47%"
          height="270px"
          class="round mb-3"
          elevation="0"
          outlined
          v-for="n in 4"
              :key="n"
        >
          <v-skeleton-loader
            type="card"
          ></v-skeleton-loader>
        </v-card>
      </div>
      <div v-else>
        <!-- KETIKA LOGIN -->
        <div v-if="isLogin" class="row pl-3 pr-3 d-flex justify-space-between pt-3">
          <v-card
            width="47%"
            height="270px"
            class="round mb-3"
            elevation="0"
            outlined
            v-for="list in listInfluencers" :key="list.id"
          >
            <!-- <v-img @click="goToDetail(list.id)" height="150" :src="list.photo" /> -->
            <v-img
              class="pointer" @click="goToDetail(list.id)" height="150"
              :src="list.photo"
            >
              <v-app-bar
                flat
                color="rgba(0, 0, 0, 0)"
              >
                <v-spacer></v-spacer>
                <v-btn
                  v-if="list.isPriority === 1"
                  elevation="0"
                  color="white"
                  fab
                  x-small
                  class="recommended"
                  aria-label="recomended"
                >
                  <v-icon class="primary--text">
                    {{ icons.mdiLightningBolt }}
                  </v-icon>
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-text class="pa-2 pb-0">
              <p v-if="list.name.length < 14" class="left-text black--text text-14 font-weight-bold mb-1">{{ list.name }}</p>
                <p v-else class="left-text black--text text-14 font-weight-bold mb-1">{{list.name.substring(0,14)+".."}}</p>
              <v-btn @click="book(list.tiktok)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-shopping-cart mr-2"></i>Book Now</v-btn>
              <p v-if="list.tiktok.length < 20" class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>{{ list.tiktok }}</p>
                <p v-else class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>{{list.tiktok.substring(0,20)+".."}}</p>
              <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_tiktok | nFormatter}} Followers</p>
            </v-card-text>
          </v-card>
        </div>
        <!-- KETIKA BELUM LOGIN -->
        <div v-else class="row pl-3 pr-3 d-flex justify-space-between pt-3">
          <v-card
            width="47%"
            height="270px"
            class="round mb-3"
            elevation="0"
            outlined
            v-for="list in listInfluencers" :key="list.id"
          >
            <!-- <v-img class="pointer" @click="goToDetail(list.id)" height="150" :src="list.photo" /> -->
            <v-img
              class="pointer" @click="goToDetail(list.id)" height="150"
              :src="list.photo"
            >
              <v-app-bar
                flat
                color="rgba(0, 0, 0, 0)"
              >
                <v-spacer></v-spacer>
                <v-btn
                  v-if="list.isPriority === 1"
                  elevation="0"
                  color="white"
                  fab
                  x-small
                  class="recommended"
                  aria-label="recomended"
                >
                  <v-icon class="primary--text">
                    {{ icons.mdiLightningBolt }}
                  </v-icon>
                </v-btn>
              </v-app-bar>
            </v-img>
            <v-card-text class="pa-2 pb-0">
              <p class="left-text black--text text-14 font-weight-bold mb-1">******** <i style="float:right" class="fas fa-eye-slash pt-0 mt-0 text-12"></i></p>
              <v-btn @click="goToLogin()" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-eye mr-2"></i> Unblock</v-btn>
              <p class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>********</p>
              <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_tiktok | nFormatter}} Followers</p>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div class="row pa-3">
        <v-btn @click="openAll()" outlined color="primary" rounded block class="text-capitalize"><i class="fas fa-user-friends mr-2"></i>Lihat Lainnya</v-btn>
      </div>
    </div>
    <!-- DESKTOP -->
    <div v-else>
      <v-card class="round" elevation="0">
        <v-card-title class="pb-5 pt-3 d-flex justify-space-between">
          <p class="title font-weight-bold black--text mb-0">TikTok Influencer</p>
          <p @click="openAll()" class="primary--text text-14 pt-1 font-weight-bold pointer">Lihat Lainnya</p>
        </v-card-title>
        <v-card-text>
          <div class="row" >
            <div
              v-if="isLoading"
              class="row pb-4 pl-6 pr-6 pt-3 d-flex justify-space-between"
              active-class="success"
            >
              <div v-for="n in 6" :key="n">
                <v-card
                  width="180px"
                  height="270px"
                  class="round mb-2"
                  elevation="0"
                  outlined
                >
                  <v-skeleton-loader
                    type="card"
                  ></v-skeleton-loader>
                </v-card>
              </div>
            </div>
            <div v-else class="row pb-4 pl-6 pr-6 pt-3 d-flex justify-space-between">
              <div v-for="list in listInfluencers"
                  :key="list.id">
              <!-- KETIKA LOGIN -->
                <v-card
                  v-if="isLogin"
                  width="180px"
                  height="270px"
                  class="round mb-2"
                  elevation="0"
                  outlined
                >
                  <v-img
                    class="pointer" @click="goToDetail(list.id)" height="150"
                    :src="list.photo"
                  >
                    <v-app-bar
                      flat
                      color="rgba(0, 0, 0, 0)"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="list.isPriority === 1"
                        elevation="0"
                        color="white"
                        fab
                        x-small
                        class="recommended"
                        aria-label="recomended"
                      >
                        <v-icon class="primary--text">
                          {{ icons.mdiLightningBolt }}
                        </v-icon>
                      </v-btn>
                    </v-app-bar>
                  </v-img>
                  <v-card-text class="pa-2 pb-0 black--text">
                    <p v-if="list.name.length < 19" class="left-text black--text text-14 font-weight-bold mb-1">{{ list.name }}</p>
                    <p v-else class="left-text black--text text-14 font-weight-bold mb-1">{{list.name.substring(0,19)+".."}}</p>
                    <v-btn @click="book(list.tiktok)" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-shopping-cart mr-2"></i>Book Now</v-btn>
                    <p v-if="list.tiktok.length < 20" class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>{{ list.tiktok }}</p>
                    <p v-else class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>{{list.tiktok.substring(0,20)+".."}}</p>
                    <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_tiktok | nFormatter}} Followers</p>
                  </v-card-text>
                </v-card>
                <!-- KETIKA BELUM LOGIN -->
                <v-card
                  v-else
                  width="180px"
                  height="270px"
                  class="round mb-2"
                  elevation="0"
                  outlined
                >
                  <v-img
                    class="pointer" @click="goToDetail(list.id)" height="150"
                    :src="list.photo"
                  >
                    <v-app-bar
                      flat
                      color="rgba(0, 0, 0, 0)"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        v-if="list.isPriority === 1"
                        elevation="0"
                        color="white"
                        fab
                        x-small
                        class="recommended"
                        aria-label="recomended"
                      >
                        <v-icon class="primary--text">
                          {{ icons.mdiLightningBolt }}
                        </v-icon>
                      </v-btn>
                    </v-app-bar>
                  </v-img>
                  <v-card-text class="pa-2 pb-0 black--text">
                    <p class="left-text black--text text-14 font-weight-bold mb-1">******** <i style="float:right" class="fas fa-eye-slash pt-0 mt-0 text-12"></i></p>
                    <v-btn @click="goToLogin()" elevation="0" block rounded class="text-capitalize mb-2 mt-2" small color="primary"><i class="fas fa-eye mr-2"></i> Unblock</v-btn>
                    <p class="mb-0 text-12"><i class="fab fa-tiktok pr-1"></i>********</p>
                    <p class="text-12 mb-0"><i class="far fa-user-circle pr-1"></i>{{list.follower_tiktok | nFormatter}} Followers</p>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mdiLightningBolt } from '@mdi/js'

export default {
  props:['data'],
  setup() {
    return {
      isLoading:true,
      isLogin:true,
      isMobileWidth:0,
      listInfluencers:[],
      icons:{
        mdiLightningBolt
      }
    }
  },
  mounted(){
    if(!localStorage.getItem('token')){
      this.isLogin = false
      this.loadInfluencersNot()
    } else {
      this.isLogin=true
      this.loadInfluencers()
    }
    this.isMobile()
    window.addEventListener('resize', () => {
      this.isMobile()
    })
  },
  methods:{
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
    loadInfluencers(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'influencers/getTiktokHomeLogin/0', {
          headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response){
            let data = response.data;
            for(let a=0; a < data.length; a++){
              axios({
                // url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            this.listInfluencers = data
            setTimeout(() => {
              this.isLoading = false
            }, 2000);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    loadInfluencersNot(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'influencers/getTiktokHome/0')
        .then(response => {
          if(response){
            // console.log(response)
            let data = response.data;
            for(let a=0; a < data.length; a++){
              data[a].city_name === null ? data[a].city_name = data[a].city:''
              axios({
                // url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                url: process.env.VUE_APP_API_ENDPOINT + 'influencers/getImage/'+data[a].photo,
                headers: {
                  'Authorization': 'Bearer '+ token
                },
                method: 'GET',
                responseType: 'blob',
              }).then((res) => {
                const blob = new Blob([res.data], { type: "application/JPG" });
                data[a].photo = URL.createObjectURL(blob);
              });
            }
            this.listInfluencers = data
            setTimeout(() => {
              this.isLoading = false
              this.loadingTable = false
            }, 2000);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    openAll(){
      this.$router.push({name:'tiktok-influencer'});
    },
    book(talent){
      window.open('https://wa.me/6285691701929?text=Halo Admin, Saya tertarik kepada talent *@'+talent+'* untuk bekerjasama. Mohon diinfokan rate cardnya ya. \n Terima kasih', '_blank');
    },
    goToDetail(id){
      this.$router.push({name:'influencer-detail', query: {user: this.encryptData(id)}});
    },
    goToLogin(){
      this.$router.push({name:'login'});
    },
    isMobile() {
      if(window.innerWidth < 778){
        this.isMobileWidth = 1
      } else if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        this.isMobileWidth = 1
      }else {
        this.isMobileWidth = 0
      }
    },
  },
  filters:{
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    nFormatter(num) {
      if (num >= 1000000000) {
          return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
      }
      if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
      }
      if (num >= 1000) {
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
      }
      return num;
    }
  }
}
</script>
